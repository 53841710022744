import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"

const BlogPostTemplate = ({ data }) => {
    const post = data.markdownRemark
    // const siteTitle = data.site.siteMetadata?.title || `Title`
    const { previous, next } = data

    return (
        <Layout>
            <Navbar />
            <section className="blog-details-area ptb-100">
                <div className="container">
                    <h1>{post.frontmatter.title}</h1>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <Img fluid={post.frontmatter.banner?.childImageSharp.fluid} alt="Thumbnail" />
                                </div>

                                <div className="article-content">
                                    <section
                                        dangerouslySetInnerHTML={{ __html: post.html }}
                                        itemProp="articleBody"
                                    />
                                </div>

                                {post.frontmatter.tags &&
                                    <div className="article-footer">
                                        <div className="article-tags">
                                            <span><i className='bx bx-purchase-tag'></i></span>
                                            {post.frontmatter.tags.map(t =><Link to={"/category/" + t}>{t}</Link>)}
                                        </div>
                                    </div>
                                }

                                <div className="article-author">
                                    {/* <div className="author-profile-header"></div> */}
                                    {/* <div className="author-profile"> */}
                                    <div className="author-profile-title">
                                        {/* <img src={user1} className="shadow-sm" alt="uu" /> */}
                                        <h4>{post.frontmatter.author}</h4>
                                        <span className="d-block">Written on {post.frontmatter.date}</span>
                                        {/* <p>Chris Orwig is a celebrated photographer, author, and writer who brings passion to everything he does. Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor.</p> */}
                                        {/* </div> */}
                                    </div>
                                </div>

                                <div className="tracer-post-navigation">

                                    {
                                        next &&
                                        <div className="next-link-wrapper">
                                            <div className="info-next-link-wrapper">
                                                <Link to={"/blog" + next.fields.slug}>
                                                    <span className="next-link-info-wrapper">
                                                        <span className="next-title">{next.frontmatter.title}</span>
                                                        <span className="meta-wrapper">
                                                            <span className="date-post">{next.frontmatter.date}</span>
                                                        </span>
                                                    </span>

                                                    <span className="image-next">
                                                        <Img fluid={next.frontmatter.banner?.childImageSharp.fluid} alt="Thumbnail" />
                                                        <span className="post-nav-title">Next</span>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    {
                                        previous &&
                                        <div className="prev-link-wrapper ml-auto">
                                            <div className="info-prev-link-wrapper">
                                                <Link to={"/blog" + previous.fields.slug}>
                                                    <span className="image-prev">
                                                        <Img fluid={previous.frontmatter.banner?.childImageSharp.fluid} alt="Thumbnail" />
                                                        <span className="post-nav-title">Prev</span>
                                                    </span>

                                                    <span className="prev-link-info-wrapper">
                                                        <span className="prev-title">{previous.frontmatter.title}</span>
                                                        <span className="meta-wrapper">
                                                            <span className="date-post">{previous.frontmatter.date}</span>
                                                        </span>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            {/* <BlogSidebar /> */}
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </Layout>
    )
}




export default BlogPostTemplate;


// export default BlogPostTemplate

export const pageQuery = graphql`
query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String, $formatString: String = "MMMM DD, YYYY", $locale: String = "de") {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: {eq: $id}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: $formatString, locale: $locale)
        description
        author
        tags
        banner {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
      fields {
        slug
      }
    }
    previous: markdownRemark(id: {eq: $previousPostId}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: $formatString, locale: $locale)
        description
        author
        banner {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
      fields {
        slug
      }
    }
    next: markdownRemark(id: {eq: $nextPostId}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: $formatString, locale: $locale)
        description
        author
        banner {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
      fields {
        slug
      }
    }
  }  
`
